<template>
  <el-dialog :append-to-body="true" :show-close="true" class="inspect-dialog" center align-center :model-value="dialogVisible" @closed="close">
    <div class="flex">
      <!-- <div
        id="J_SaleList"
        ref="saleList"
        class="sale-list relatvie animation-height w-120 overflow-y-auto"
        :class="bIsShowHistory ? 'h-740' : 'h-700'"
      >
        <div
          class="arrow-top absolute top-0 z-10 flex items-center justify-center"
          :class="prevClick ? 'cursor-pointer text-blue' : 'text-gray-light'"
          @click="pagePrev"
        >
          <i class="iconfont icon-a-lujing2" />
        </div>
        <div class="inspect-list pb-45 pt-45">
          <div
            v-for="skin in list"
            :class="[`inspect-item mb-10 mt-10 flex items-center justify-center`, skin.id === selectItem.id ? 'active' : '']"
            @click="select(skin)"
          >
            <img :src="skin.imageUrl" class="inspect-img" />
            <div class="inspect-price">{{ monetaryUnit() }}{{ skin.price }}</div>
          </div>
        </div>
        <div
          class="arrow-bottom absolute bottom-0 flex items-center justify-center"
          :class="nextClick ? 'cursor-pointer text-blue' : 'text-gray-light'"
          @click="pageNext"
        >
          <i class="iconfont icon-a-lujing2" style="transform: rotate(180deg)" />
        </div>
      </div> -->
      <div class="inspect-info animation-height">
        <div class="mb-30 flex">
          <div class="info-left">
            <div class="info-img relative flex items-center" :class="selectItem?.assetInfo?.inspectImgs ? 'flex-col' : ''">
              <template v-if="selectItem?.assetInfo?.inspectImgs && selectItem?.assetInfo?.inspectImgs.length">
                <img v-for="(img, index) in selectItem?.assetInfo?.inspectImgs" :key="index" :src="img" alt="" />
              </template>

              <img v-else :src="selectItem?.classInfo?.imageUrl" alt="" />

              <div class="action-list flex items-center">
                <a :href="selectItem?.assetInfo?.inspectUrl" class="btn flex w-134 items-center justify-center" target="_blank">
                  <i class="iconfont icon-CS2 text-color-white mr-5 text-20"></i>
                  <span class="text-color-white text-14">{{ $t('游戏中检视') }}</span>
                </a>
              </div>
            </div>
            <!-- <div class="mt-24 flex items-center">
              来源
              <span class="info-c5game" />
              C5GAME
            </div> -->
          </div>
          <div class="info-right ml-30 w-520">
            <div class="item-name text-22 font-semibold leading-snug">
              {{ selectItem?.classInfo?.name }}
            </div>
            <div class="mt-17 flex items-center justify-between">
              <div>
                <span class="wear-tag mr-10 text-white" :style="{ backgroundColor: CS_EXTERIOR[selectItem?.exterior?.nameZh]?.color }">
                  {{ selectItem?.exterior?.nameZh }}
                </span>
                <span
                  class="st-tag text-white"
                  :style="{
                    backgroundColor: `${CS_QUALITY[selectItem?.quality?.nameZh]?.color}`
                  }"
                >
                  {{ selectItem?.quality?.nameZh }}
                </span>
              </div>

              <div class="flex cursor-pointer items-center" @click="shareLink(selectItem?.assetInfo?.fingerprint)">
                <i class="iconfont icon-fenxiang text-color-lightblue mr-10 text-20"></i>
                <span class="text-color-lightblue text-14">{{ $t('分享') }}</span>
              </div>
            </div>
            <div class="mt-10 flex h-46 w-520 items-center rounded border border-[#DDDFE7] pl-10 text-16 text-[#979CAE]">
              <span>{{ $t('饰品指纹') }}:</span>
              <span class="ml-10 w-343 overflow-hidden text-ellipsis whitespace-nowrap">{{ selectItem?.assetInfo?.fingerprint }}</span>
              <i class="iconfont icon-fuzhi ml-60 cursor-pointer text-16" @click="copyText(selectItem?.assetInfo?.fingerprint)"></i>
            </div>
            <div class="mt-20 flex w-660">
              <div class="text-color-lightgray mr-20 flex h-46 w-250 items-center justify-between rounded border border-[#DDDFE7] px-14">
                <span>{{ $t('图案模板') }}:</span>
                <span
                  >{{ selectItem?.assetInfo?.paintSeed }}
                  <span
                    v-if="
                      commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.group &&
                      commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.group != 'phase'
                    "
                    :style="{
                      color: commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.color
                    }"
                  >
                    {{ `(${commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.alias})` }}
                  </span>
                </span>
              </div>
              <div class="text-color-lightgray flex h-46 w-250 items-center justify-between rounded border border-[#DDDFE7] px-14">
                <span>{{ $t('皮肤编号') }}:</span>
                <span
                  >{{ selectItem?.assetInfo?.paintIndex }}

                  <span
                    v-if="commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.group == 'phase'"
                    :style="{
                      color: commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.color
                    }"
                  >
                    ({{ commonDictionary?.specialStyleMap[selectItem?.assetInfo?.specialStyleAndFade?.specialStyle?.toLowerCase()]?.alias }})
                  </span></span
                >
              </div>
            </div>
            <div class="mt-20 h-85 w-520 rounded border border-[#DDDFE7] px-14 py-20">
              <div class="text-color-lightgray mb-20">{{ $t('磨损') }}：{{ selectItem?.assetInfo?.floatWear }}</div>
              <wearbar :wear="selectItem?.assetInfo?.floatWear" />
            </div>
            <div class="mt-30 flex items-center justify-between">
              <div class="sticker-list flex">
                <mytooltip v-for="sticker in selectItem?.assetInfo?.stickers" :key="sticker.stickerId" placement="bottom">
                  <div class="sticker-item mr-20 text-center">
                    <img :src="sticker.imgUrl" class="w-50" alt="" />
                    <div class="text-color-lightgray mt-8">{{ parseInt((1 - sticker.wear) * 100) }}%</div>
                  </div>

                  <template #content>
                    <div class="py-20 text-14 text-gray-light">
                      <div class="mb-10">{{ sticker?.name }}</div>
                      <div>
                        <span class="text-black">价格: </span>
                        <span class="text-color-orange">{{ monetaryUnit() }}{{ sticker.lowestPrice ? currencyTransform(sticker?.lowestPrice) : '-' }}</span>
                      </div>
                    </div>
                  </template>
                </mytooltip>
              </div>

              <div class="flex flex-col items-center">
                <div v-if="selectItem?.assetInfo?.stickers" class="text-14 text-[#979CAE]">
                  {{ $t('已贴印花市场价') }} <span class="text-[#F87600]">¥{{ calMarketPrice(selectItem?.assetInfo?.stickers) }}</span>
                </div>
                <div class="text-28 font-semibold text-[#F87600]">{{ monetaryUnit() }}{{ currencyTransform(selectItem?.assetInfo?.evaluatePrice) }}</div>

                <!-- <a :href="`https://www.c5game.com/csgo/${selectItem?.classInfo?.itemId}/${selectItem?.classInfo?.marketHashName}/sell`" target="_blank">
                  <div class="pointer flex h-46 w-95 items-center justify-center rounded border border-[#0252D9] text-16 text-[#0252D9]">
                    购买
                  </div>
                </a> -->
              </div>
            </div>
            <!-- <div class="right-look flex cursor-pointer items-center justify-center" @click="showHistory">
              <span class="text-16">查看历史拥有者</span>
              <span class="arrow ml-10" :class="bIsShowHistory ? 'arrowUp' : ''"></span>
            </div> -->
          </div>
        </div>
        <div v-if="itemDesc" class="text-color-lightgray mb-20 flex items-center justify-between rounded border border-[#DDDFE7] px-20 py-14">
          <div class="flex items-center">
            <div class="mr-10">
              <img src="https://cdn.ok-skins.com/steam-stock/category/trend-dialog/robot.png" alt="" />
            </div>
            <p class="w-700" v-html="itemDesc"></p>
          </div>
          <div class="w-80">
            <el-badge value="hot"  class="item" :offset="[-5, 5]">
              <a :href="localePath(`/cs2/${selectItem?.classInfo?.marketHashName}`)" target="_blank" style="color: #0252D9">{{ $t('查看走势') }}>></a>
            </el-badge>

          </div>
        </div>
        <!-- <div v-if="bIsShowHistory" v-loading="showHistoryLoading" class="item-history mt-10 rounded"> -->
        <div v-loading="showHistoryLoading" class="item-history mt-30">
          <div v-if="trackerData && trackerData[0]" class="history-data">
            <div class="mb-25 flex justify-between">
              <div class="text-16 font-semibold">{{ $t('历史拥有者') }} ({{ $t('累计换手{num}次', { num: trackerData.length }) }})</div>
              <div v-if="trackerData && trackerData[0]" class="text-color-lightblue">
                {{ $t('上次交易时间') }} {{ dayjs(trackerData[0].firstOwnedTime * 1000).format('YYYY-MM-DD') }}
              </div>
            </div>
            <div class="avatar-details flex flex-wrap">
              <a v-for="(item, index) in trackerData" :key="index" :href="`/inventory/${item.steamId}`" target="_blank" class="relative mb-20 block w-129 text-center">
                <el-avatar class="img m-[auto]" :src="item.avatar">
                  <img src="https://avatars.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg" alt="avatar" />
                </el-avatar>
                <div class="mt-10 h-20 truncate text-14">
                  {{ item.nickname }}
                </div>
                <div class="mt-10 text-14">
                  {{ dayjs(item.firstOwnedTime * 1000).format('YYYY-MM-DD') }}
                </div>

                <div v-if="index == 0 && item.inInventory" class="current-owner">{{ $t('当前拥有者') }}</div>
                <div v-if="trackerData.length > 1 && index == trackerData.length - 1" class="lastest-owner">{{ $t('最早拥有者') }}</div>
              </a>
            </div>
          </div>
          <div v-else class="history-empty flex items-center justify-center">
            <div v-if="userInfo?.nickname" class="text-center">
              <p class="text-color-gray text-16">{{ $t('抱歉，您的饰品追踪可用次数不足，分享可得追踪次数') }}</p>
              <el-button class="mt-32" @click="copyShare">
                <el-row align="middle" class="text-color-white text-16">
                  <i class="iconfont icon-lianjie mr-5 text-18"></i>
                  <span>{{ $t('分享链接') }}</span>
                </el-row>
              </el-button>
              <p class="text-color-orange mt-10 text-14">{{ $t('成功分享次数+1') }}</p>
            </div>

            <div v-else class="flex flex-col items-center">
              <p class="text-color-gray text-16">{{ $t('您还没有登录，请登录后查看该饰品历史持有人') }}</p>

              <el-button class="mt-32" @click="steamLogin">
                <span class="text-color-white text-16">{{ $t('点击登录') }}</span>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import wearbar from '~/components/common/wearbar.vue'
import mytooltip from '~/components/common/mytooltip.vue'
import copyShareUrl from '~/utils/copyshareUrl'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

import { copy } from '#imports'
import { CS_EXTERIOR, CS_QUALITY } from '~/constants/item'
import { add, handleTagMap } from '~/utils/index'
import { getItemDesc, getTraceInfo } from '~/api/steam'
import { getCommonDictionary } from '~/api/common'

dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

const store = useNuxtStore()
const userInfo = store.userInfo
const route = useRoute()
const router = useRouter()

const emit = defineEmits(['close'])
const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    }
  },
  list: {
    type: Array,
    default: () => {
      return []
    }
  },
  dialogVisible: {
    type: Boolean,
    default: false
  }
})
let trackerData = ref([])
let selectItem = ref(props.item)
const bIsShowHistory = ref(false)
const showHistoryLoading = ref(false)
const prevClick = ref(false)
const nextClick = ref(true)

const { data: commonDictionary } = await useAsyncData(getCommonDictionary, {
  server: false
})

const { data: itemDesc } = await getItemDesc({ typeVal: props.item?.classInfo?.itemId })

console.log('itemDesc', itemDesc)

onMounted(() => {
  // selectItem.value.classInfo.tag = {}
  // for (const [rawKey, value] of Object.entries(selectItem.value?.classInfo?.tagMap)) {
  //   // rawKey格式： Weapon#weapon_awp
  //   const field = rawKey.split('#')[0].toLocaleLowerCase()
  //   selectItem.value.classInfo.tag[field] = value
  // }

  handleTagMap(selectItem.value, commonDictionary.value, 'classInfo')
  if (userInfo?.nickname) {
    readHistory()
  }

  // 更改路由参数
  const newQuery = Object.assign({}, route.query, { hash: selectItem.value.assetInfo?.fingerprint })
  router.push({
    path: '',
    query: newQuery
  })

  // itemTracker({ queryContent: props.item.fingerprint }).then((res) => {
  //   console.log("res", res);
  // });
})

function select(skin) {
  bIsShowHistory.value = false
  selectItem.value = skin
}

function close() {
  emit('close')
}

// 计算印花市场在售价的总和
function calMarketPrice(stickers) {
  let price = 0
  stickers.forEach(config => {
    price = add(price, config.lowestPrice ?? 0)
  })
  return price
}

// 读取历史拥有者
const readHistory = () => {
  showHistoryLoading.value = true
  getTraceInfo({
    content: selectItem.value.assetInfo?.fingerprint
  })
    .then(res => {
      showHistoryLoading.value = false
      trackerData.value = res.data.traceSteamList
      if (res.data.traceTimes) {
        const store = useNuxtStore()
        const userInfo = JSON.parse(JSON.stringify(store.userInfo))
        userInfo.traceTimes = res.data.traceTimes
        store.setUserInfo(userInfo)
      }
    })
    .catch(err => {
      showHistoryLoading.value = false
      trackerData.value = []
    })
}

// 查看历史拥有者
const showHistory = () => {
  bIsShowHistory.value = !bIsShowHistory.value

  showHistoryLoading.value = true
  getTraceInfo({
    content: selectItem.value.assetInfo?.fingerprint
  })
    .then(res => {
      showHistoryLoading.value = false
      trackerData.value = res.data.traceSteamList
      if (res.data.traceTimes) {
        const store = useNuxtStore()
        const userInfo = JSON.parse(JSON.stringify(store.userInfo))
        userInfo.traceTimes = res.data.traceTimes
        store.setUserInfo(userInfo)
      }
    })
    .catch(() => {
      showHistoryLoading.value = false
      trackerData.value = []
    })
}

// 左侧列表-上一页
const pagePrev = () => {
  const scrollTop = saleList.value.scrollTop

  nextClick.value = true
  if (scrollTop === 0) {
    prevClick.value = false
  } else {
    document.getElementById('J_SaleList').scrollTo({
      top: scrollTop - 105, // 95为一个元素的距离
      behavior: 'smooth'
    })
  }
}
// 左侧列表-下一页
const pageNext = () => {
  const scrollTop = saleList.value.scrollTop
  const maxHeight = saleList.value.scrollHeight - saleList.value.offsetHeight

  prevClick.value = true
  if (scrollTop < maxHeight) {
    document.getElementById('J_SaleList').scrollTo({
      top: scrollTop + 105, // 95为一个元素的距离
      behavior: 'smooth'
    })
  } else {
    nextClick.value = false
  }
}

// 头部分享
const shareLink = id => {
  copy(window.location.href)
  myMessage({
    message: '分享链接已复制'
  })
}

// 复制
const copyText = val => {
  copy(val)
  myMessage({ message: '复制成功', type: 'success' })
}

// 分享链接复制
const copyShare = () => {
  const remainTime = copyShareUrl()
  myMessage({
    type: 'success',
    message: '分享链接已复制',
    duration: 2000
  })
  userInfo.value = {
    ...userInfo.value,
    traceTimes: remainTime
  }
  store.setUserInfo(userInfo)
}

// 登录
const steamLogin = () => {
  const store = useNuxtStore()
  store.setLoginDialog(true)
  emit('close')
  // const location = window.location
  // const steamLoginUrl = `https://steamcommunity.com/openid/login?openid.mode=checkid_setup&openid.ns=http://specs.openid.net/auth/2.0&openid.realm=${location.origin}&openid.sreg.required=nickname,email,fullname&openid.assoc_handle=None&openid.return_to=${location.origin + '/login/steam'}&openid.ns.sreg=http://openid.net/extensions/sreg/1.1&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select`
  // window.open(steamLoginUrl, '_blank', 'popup,width=500,height=600')
  // window.addEventListener('storage', function (e) {
  //   if (e.key == 'steamloginStatus') {
  //     console.log('storageValue', e.newValue)
  //     if (e.newValue) {
  //       window.localStorage.removeItem('steamloginStatus')
  //       myMessage({
  //         type: 'success',
  //         message: 'Steam登录成功',
  //         duration: 2000,
  //         onClose: () => {
  //           location.reload()
  //         }
  //       })
  //     } else {
  //       window.localStorage.removeItem('steamloginStatus')
  //       myMessage({
  //         type: 'error',
  //         message: 'Steam登录失败，请重试',
  //         duration: 3000
  //       })
  //     }
  //   }
  // })
  //
  // emit('close')
}
</script>

<style lang="scss">
@import url('~/assets/styles/_color.scss');

.inspect-dialog {
  // width: 1140px;
  box-sizing: border-box;
  padding-top: 0;
  width: 1010px;
  background: transparent !important;
  z-index: 6;

  .el-dialog__header {
    padding: 0 !important;

    .el-dialog__headerbtn {
      width: 16px;
      height: 16px;
      line-height: 1;
      font-size: 16px;
      top: 16px;
      right: 16px;

      .el-dialog__close {
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-family: 'iconfont';
        font-style: normal;

        &:hover {
          animation: loading 0.4s linear;
        }

        &::before {
          content: '\e6d2';
        }

        svg {
          display: none;
        }
      }
    }
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .arrow-top {
    width: 120px;
    height: 45px;
    background: #ffffff;
    border-radius: 4px;
  }
  .sale-list {
    scrollbar-width: none;
  }
  .inspect-list {
    width: 120px;
    // height: 680px;
    // max-height: 100%;
    // margin-top: -45px;
    // margin-bottom: -45px;
    // overflow-y: scroll;
  }
  .arrow-bottom {
    width: 120px;
    height: 45px;
    background: #ffffff;
    border-radius: 4px;
  }
  .inspect-item {
    width: 120px;
    height: 95px;
    background: linear-gradient(180deg, #e6eef9 0%, #f7fafd 100%);
    border-radius: 4px;
    position: relative;
    &.active {
      border: 2px solid #0252d9;
    }
  }
  .inspect-img {
    width: 100px;
    height: 75px;
  }
  .inspect-price {
    width: 120px;
    height: 23px;
    background: linear-gradient(90deg, rgba(248, 118, 0, 0.3) 0%, rgba(248, 118, 0, 0) 50%, transparent 70%);
    border-radius: 0px 4px 4px 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #f87600;
  }
  .inspect-info {
    width: 1010px;
    // height: 100%;
    background: #ffffff;
    border-radius: 4px;
    // margin-left: 10px;
    padding: 30px;
    box-sizing: border-box;
  }
  .info-img {
    width: 400px;
    // height: 460px;
    height: 400px;
    border-radius: 4px;
    background: url(https://resources.zbt.com/steam-stock/index/item-detail-dialog/dialog-bg.webp) center no-repeat;
    background-size: cover;
    overflow: hidden;
    .action-list {
      position: absolute;
      right: 20px;
      bottom: 20px;

      .btn {
        height: 36px;
        background: rgba($black-color, 0.6);
        border-radius: 4px;
        border: 0 none;
      }
    }
  }
  .info-c5game {
    display: inline-block;
    width: 26px;
    height: 26px;
    background: url(../../assets/images/detail/c5-logo.png) no-repeat center;
    background-size: 100% 100%;
    margin-left: 9px;
    margin-right: 3px;
  }
  .item-history {
    transition: height 0.3s ease;
    transition-delay: 0.3s;

    .history-data {
      height: 212px;
      padding: 20px 20px 0px 20px;
      background-color: #ffffff;
      border: 1px solid #dddfe7;
      border-radius: 4px;
    }

    .avatar-details {
      color: $light-gray-color;
      max-height: 142px;
      overflow: hidden;
      overflow-y: auto;

      .img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #8e3331;
      }
    }
    .dash-border {
      width: 57px;
      height: 2px;
      border-top: 1px dashed #0252d9;
      position: relative;
      margin-top: 30px;
      .arrow {
        width: 9px;
        height: 9px;
        border: 1px solid #0252d9;
        position: absolute;
        left: 25px;
        top: -5px;
        transform: rotate(-45deg);
        border-right: transparent !important;
        border-bottom: transparent !important;
      }
    }
    .current-owner {
      color: #ffffff;
      position: absolute;
      width: 80px;
      height: 18px;
      background: #f87600;
      border-radius: 9px;
      text-align: center;
      line-height: 18px;
      top: 46px;
      left: 50%;
      margin-left: -40px;
    }
    .lastest-owner {
      color: #ffffff;
      position: absolute;
      width: 80px;
      height: 18px;
      background: #0dab62;
      border-radius: 9px;
      text-align: center;
      line-height: 18px;
      top: 46px;
      left: 50%;
      margin-left: -40px;
    }

    .history-empty {
      background: url('~/assets/images/layouts/trace-bg.png') no-repeat;
      width: 950px;
      height: 210px;

      .el-button {
        width: 180px;
        height: 46px;
        line-height: 46px;
        border-color: $primary-color;
        background-color: $primary-color;
        border-radius: 4px;
      }
    }
  }

  .info-right {
    position: relative;

    .right-look {
      width: 520px;
      height: 46px;
      color: $light-gray-color;
      background: rgba($light-gray-color, 0.1);
      border-radius: 4px;
      position: absolute;
      bottom: 0;

      .arrow {
        background: url('~/assets/images/tracker/icon-arrow1.webp') no-repeat;
        display: block;
        width: 18px;
        height: 18px;
      }

      .arrowUp {
        transform: rotateX(180deg);
      }

      &:hover {
        color: $primary-color;
        background: rgba($primary-color, 0.1);

        .arrow {
          background: url('~/assets/images/tracker/icon-arrow2.webp') no-repeat;
        }
      }
    }
  }

  .animation-height {
    transition: height 0.3s ease;
  }

  @keyframes loading {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
.el-message {
  z-index: 9999 !important;
}
</style>
